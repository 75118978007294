import React, {StrictMode, useContext, useEffect} from 'react'
import {createRoot} from 'react-dom/client'

import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {BrowserRouter, Navigate, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {de} from "date-fns/locale/de";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFnsV3";
import {SessionContext, SessionProvider} from "./auth/SessionProvider";
import RootRoute from "./routes/RootRoute";
import App from "./ui/App";
import Session from "./auth/Session";
import IdeasPage from "./ui/ideas/IdeasPage";
import ConnectionsPage from "./ui/connections/ConnectionsPage";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {AppFwOverlay} from "./appfw/ui/AppFwOverlay";
import InvitationPage from "./ui/invitations/InvitationPage";
import W2d2dRoutes from "./W2d2dRoutes";
import IdeaPage from "./ui/ideas/IdeaPage";
import JoinIdeaRoute from "./routes/ideas/JoinIdeaRoute";
import EditIdeaRoute from "./routes/ideas/EditIdeaRoute";
import EditIdeaPage from "./ui/ideas/EditIdeaPage";
import SettingsPage from "./ui/settings/SettingsPage";

const theme = createTheme({
    palette: {

        // primary: {
        //     main: "#fff",
        //     contrastText: "#eee",
        // },
        //
        // secondary: {
        //     main: "#666",
        //     contrastText: "#111",
        // },
    },
})

function AppWrapper() {

    const navigate = useNavigate()
    const location = useLocation()

    const session = useContext<undefined | "NO_SESSION" | Session>(SessionContext)

    useEffect(() => {
        if (session === "NO_SESSION") {
            console.log("Session initialized, but not logged in ==> redirect to login page")

            let path: null | string = location.pathname
            if (path && path !== W2d2dRoutes._ROOT) {
                if (path.startsWith("/")) {
                    path = path.substring(1)
                }
            } else {
                path = null
            }
            navigate(W2d2dRoutes.toLogin(path))
        }
    }, [session])

    if (session === undefined) {
        // still initializing
        return null
    }

    if (session === "NO_SESSION") {
        console.log("location.pathname", location.pathname)
        // Special case:
        //   - No session (already there, or redirected by the useEffect above)
        //   - The "Login Route" (= RootRoute) is the only accessible route
        return (
            <Routes>
                <Route path={W2d2dRoutes._ROOT} element={<RootRoute/>}/>
            </Routes>
        )
    }

    // Valid session, normal routes
    return (
        <Routes>
            <Route path={W2d2dRoutes._ROOT} element={<RootRoute/>}/>
            <Route path={W2d2dRoutes.connections} element={<App session={session} section={"connections"}/>}>
                <Route index element={<ConnectionsPage/>}/>
            </Route>
            <Route path={W2d2dRoutes.ideas} element={<App session={session} section={"ideas"}/>}>
                <Route index element={<IdeasPage user={session.user}/>}/>
                <Route path=":id">
                    <Route index element={
                        <AppFwOverlay title="Idee">
                            <IdeaPage session={session}/>
                        </AppFwOverlay>
                    }/>
                    <Route path="edit" element={<EditIdeaRoute />}/>
                    <Route path="join/:token" element={
                        <AppFwOverlay title="Idee">
                            <JoinIdeaRoute />
                        </AppFwOverlay>
                    }/>
                </Route>
                <Route path="add" element={<AppFwOverlay title="Idee vorschlagen"/>}>
                    <Route index element={<EditIdeaPage/>}/>
                </Route>
            </Route>
            <Route path={W2d2dRoutes.invitations} element={<App session={session} section={"connections"}/>}>
                <Route path=":id" element={<AppFwOverlay title="Einladung"/>}>
                    <Route index element={<InvitationPage session={session}/>}/>
                </Route>
            </Route>
            <Route path={W2d2dRoutes.settings} element={<App session={session} section={"settings"}/>}>
                <Route index element={<SettingsPage session={session}/>}/>
            </Route>
            <Route path="*" element={<Navigate to={W2d2dRoutes._ROOT} replace/>}/>
        </Routes>
    )
}

const queryClient = new QueryClient()

const node = document.getElementById('root') as HTMLElement
createRoot(node).render(
    <StrictMode>
        <CssBaseline/>
        <QueryClientProvider client={queryClient}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
                <ThemeProvider theme={theme}>
                    <BrowserRouter>
                        <SessionProvider>
                            <AppWrapper/>
                        </SessionProvider>
                    </BrowserRouter>
                </ThemeProvider>
            </LocalizationProvider>
        </QueryClientProvider>
    </StrictMode>,
)
