import {BottomNavigation, BottomNavigationAction, Box, Paper} from "@mui/material";
import React from "react";
import Session from "../auth/Session";
import {OnlinePrediction, People, Settings} from "@mui/icons-material";
import {Outlet, useNavigate} from "react-router-dom";
import W2d2dRoutes from "../W2d2dRoutes";

interface AppProps {
    session: Session,
    section: "ideas" | "connections" | "settings"
}

export default function App(props: AppProps) {

    const navigate = useNavigate()
    const [menuValue, setMenuValue] = React.useState(props.section)

    return (

        <Box
            sx={{
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Outlet/>
            <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0}} elevation={3}>
                <BottomNavigation
                    showLabels
                    value={menuValue}
                    onChange={(_, newValue) => {
                        setMenuValue(newValue);
                    }}
                >
                    <BottomNavigationAction
                        label="Ideen"
                        value="ideas"
                        icon={<OnlinePrediction/>}
                        onClick={() => navigate(W2d2dRoutes.ideas)}
                    />
                    <BottomNavigationAction
                        label="Freunde"
                        value="connections"
                        icon={<People/>}
                        onClick={() => navigate(W2d2dRoutes.connections)}
                    />
                    <BottomNavigationAction
                        label="Einstellungen"
                        value="settings"
                        icon={<Settings/>}
                        onClick={() => navigate(W2d2dRoutes.settings)}
                    />
                </BottomNavigation>
            </Paper>
        </Box>
    )
}
