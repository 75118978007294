import {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import W2d2dRoutes from "../../W2d2dRoutes";
import {w2d2dApiClient} from "../../api-client";

export default function JoinIdeaRoute() {

    const navigate = useNavigate()
    const {id, token} = useParams()

    useEffect(() => {
        console.log("JoinIdeaRoute: useEffect called")

        if (!id || !token) {
            navigate(W2d2dRoutes.ideas)
            return
        }

        let active = true
        w2d2dApiClient.joinIdea({id: id, shareToken: token})
            .then(() => {
                if (active) {
                    navigate(W2d2dRoutes.ideas)
                }
            })

        return () => {
            active = false
        }
    }, [])

    // TODO: progress indicator or spinner?
    return null
}
