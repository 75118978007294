import {Box, Button, Stack, Typography} from "@mui/material";
import {Apple, Google} from "@mui/icons-material";
import {w2d2dApiClient} from "../api-client";
import {useSearchParams} from "react-router-dom";

interface SplashScreenProps {
    isLoading?: boolean
}

export default function SplashScreen({isLoading}: SplashScreenProps) {

    const [searchParams] = useSearchParams()

    const path = searchParams.get("path")

    let googleHref = w2d2dApiClient.appFwApiBaseUrl + "auth/oidc/w2d2d/google/start"
    if (path) {
        googleHref = googleHref + "?path=" + encodeURIComponent(path)
    }

    let appleHref = w2d2dApiClient.appFwApiBaseUrl + "auth/oidc/w2d2d/apple/start"
    if (path) {
        appleHref = appleHref + "?path=" + encodeURIComponent(path)
    }

    return (
        <Box
            sx={{
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                // backgroundColor: '#000',
            }}
        >
            <Typography
                variant="h5"
                sx={{
                    marginBottom: '2rem',
                    fontWeight: 'bold',
                }}
            >
                What to do, today?
            </Typography>

            <Typography
                variant="subtitle1"
                sx={{
                    marginBottom: '5rem',
                }}
            >
                Triff Deine Freunde
            </Typography>


            {/*<Box*/}
            {/*    component="img"*/}
            {/*    src="media/w2d2d-splash.png"*/}
            {/*    alt="Logo"*/}
            {/*    sx={{*/}
            {/*        width: '400px',*/}
            {/*        marginBottom: '0.5rem',*/}
            {/*    }}*/}
            {/*/>*/}

            {
                !isLoading && (

                    <Stack direction="column" spacing={2}>

                        <Button
                            href={googleHref}
                            variant="contained"
                            startIcon={<Google/>}
                            sx={{
                                // We should use official Google colors
                                backgroundColor: '#4285F4',
                                color: '#fff',
                                '&:hover': {
                                    backgroundColor: '#357ae8',
                                },
                                padding: '0.75rem',
                                paddingLeft: '2rem',
                                paddingRight: '2rem',
                                borderRadius: '8px',
                                textTransform: 'none',
                                fontWeight: 'bold',
                                fontSize: '1rem',
                                minWidth: '300px',
                            }}
                        >
                            Mit Google anmelden
                        </Button>

                        <Button
                            href={appleHref}
                            variant="contained"
                            startIcon={<Apple />}
                            sx={{
                                color: '#fff',
                                backgroundColor: '#000',
                                '&:hover': {
                                    backgroundColor: '#333',
                                },
                                padding: '0.75rem',
                                paddingLeft: '2rem',
                                paddingRight: '2rem',
                                borderRadius: '8px',
                                textTransform: 'none',
                                fontWeight: 'bold',
                                fontSize: '1rem',
                                minWidth: '300px',
                            }}
                        >
                            Mit Apple anmelden
                        </Button>
                    </Stack>
                )
            }
        </Box>
    )
}
