import {useState} from "react"

export function _initializeInstallationId() {
    let installationId = localStorage.getItem('appfw.installationId')
    if (!installationId) {
        installationId = self.crypto.randomUUID()
        localStorage.setItem('appfw.installationId', installationId)
    }
    return installationId
}

export default function useCommons() {

    const [installationId] = useState<string>(_initializeInstallationId())

    return {
        installationId
    }
}
