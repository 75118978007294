import {createContext, ReactNode, useCallback, useEffect, useState} from 'react'
import Session from "./Session"
import {useSearchParams} from "react-router-dom"
import {w2d2dApiClient} from "../api-client";
import {_initialize, _unsubscribe} from "../appfw/notifications/useNotifications";

export const SessionContext = createContext<undefined | "NO_SESSION" | Session>(undefined)

interface SessionProviderProps {
    children: ReactNode | ReactNode[]
}

export const SessionProvider = (props: SessionProviderProps) => {

    const [session, setSession] = useState<undefined | "NO_SESSION" | Session>(undefined)
    const [searchParams, setSearchParams] = useSearchParams()

    const updateSession = useCallback((session: null | Session) => {
        // update Session-ID in local storage
        if (session) {
            localStorage.setItem('sessionId', session.id)
            setSession(session)
            _initialize(session)
        } else {
            console.log("!!! removing sessionId from local storage")
            localStorage.removeItem('sessionId')
            setSession("NO_SESSION")
            _initialize("NO_SESSION")
        }
    }, [session, setSession])

    useEffect(() => {
        let active = true

        console.log("SessionProvider: useEffect called")

        if (session != null) {
            console.log("We already have a valid session, skipping session validation", session)
            return
        }

        console.log(" ==> 2")

        const logout = async () => {
            console.log("logout called")
            const storedSessionId = localStorage.getItem('sessionId')
            if (storedSessionId != null) {
                try {
                    await w2d2dApiClient.logout({sessionId: storedSessionId})
                } catch (err) {
                    console.log(`error on logout (${storedSessionId}): ${err}`)
                }
            }
            updateSession(null)
            await _unsubscribe(false)
        }

        console.log(" ==> 3")

        const sessionIdFromQuery = searchParams.get('sessionId')
        if (sessionIdFromQuery) {
            console.log("Got 'sessionId' from query: ", sessionIdFromQuery)
            // localStorage.setItem('sessionId', sessionIdFromQuery)
            // setSearchParams((params) => {
            //     params.delete('sessionId')
            //     return params
            // })
            // return
        }

        console.log(" ==> 4")

        const sessionId = sessionIdFromQuery || localStorage.getItem('sessionId')
        console.log("sessionId: ", sessionId)
        if (sessionId) {
            // validate session
            w2d2dApiClient.sessionAuth({sessionId: sessionId})
                .then((authResponse) => {
                    console.log(`got auth response, active=${active}: `, authResponse)
                    if (!active) {
                        return
                    }
                    updateSession(
                        new Session({
                            id: authResponse.sessionId,
                            user: authResponse.user,
                            logout: logout,
                        })
                    )
                })
                .catch((err) => {
                    console.log(`error on validating session, active=${active}:`, err)
                    if (!active) {
                        return
                    }
                    updateSession(null)
                })
        } else {
            console.log(" ==> 5")
            updateSession(null)
        }

        return () => {
            active = false
        }
    }, [searchParams, session, setSession, setSearchParams])

    return <SessionContext.Provider value={session}>{props.children}</SessionContext.Provider>
}
