import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import W2d2dRoutes from "../../W2d2dRoutes";
import {Idea, IdeaAccessors, w2d2dApiClient} from "../../api-client";
import {AppFwOverlay} from "../../appfw/ui/AppFwOverlay";
import EditIdeaPage, {IdeaDraft} from "../../ui/ideas/EditIdeaPage";
import {LinearProgress, Typography} from "@mui/material";

export default function EditIdeaRoute() {

    const navigate = useNavigate()
    const {id} = useParams()

    const [idea, setIdea] = useState<undefined | null | Idea>(undefined)
    const [ideaAccessors, setIdeaAccessors] = useState<undefined | null | IdeaAccessors>(undefined)

    useEffect(() => {
        if (!id) {
            navigate(W2d2dRoutes.ideas)
            return
        }

        w2d2dApiClient.getIdea({id})
            .then(idea => { setIdea(idea) })
            .catch(() => { setIdea(null) })
        w2d2dApiClient.getIdeaAccessors({id})
            .then(ideaAccessors => { setIdeaAccessors(ideaAccessors) })
            .catch(() => { setIdeaAccessors(null) })
    }, [setIdea])

    if (idea && ideaAccessors) {
        let startsAt = new Date()
        if (idea.startsAt) {
            startsAt = new Date(idea.startsAt)
        }
        let duration = 2
        if (idea.startsAt && idea.endsAt) {
            let diff = Date.parse(idea.endsAt) - Date.parse(idea.startsAt)
            if (diff > 0) {
                duration = diff / (1000 * 60 * 60)
            }
        }
        const ideaDraft: IdeaDraft = {
            id: idea.id,
            title: idea.title,
            startsAt: startsAt,
            duration: duration,
            accessors: ideaAccessors,
        }
        return (
            <AppFwOverlay title="Bearbeiten">
                <EditIdeaPage ideaDraft={ideaDraft}/>
            </AppFwOverlay>
        )
    }

    if (idea === null || ideaAccessors === null) {
        // TODO: general not found page?
        return (
            <AppFwOverlay title="Bearbeiten">
                <Typography variant="h4">Idee nicht gefunden</Typography>
            </AppFwOverlay>
        )
    }

    return (
        <AppFwOverlay title="Bearbeiten">
            <LinearProgress/>
        </AppFwOverlay>
    )
}
