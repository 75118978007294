import {useEffect} from "react"

export default function useWebPush(topic: string, callback: ((message: any) => void)) {

    const _swMessageListener = (message: any) => {

        console.log(`[pusher] got message: `, message)

        const data = message.data
        if (data && data.topic === topic) {
            callback(data)
        }
    }

    useEffect(() => {

        if ('serviceWorker' in navigator) {
            console.log(`[pusher] register message listener for '${topic}': `, _swMessageListener)
            navigator.serviceWorker.addEventListener('message', _swMessageListener)
        }

        return () => {
            if ('serviceWorker' in navigator) {
                console.log(`[pusher] unregister message listener for '${topic}'`, _swMessageListener)
                navigator.serviceWorker.removeEventListener('message', _swMessageListener)
            }
        }
    }, [])
}
