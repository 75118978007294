import {useCallback, useContext, useEffect, useState} from "react"
import {w2d2dApiClient} from "../../api-client";
import {_initializeInstallationId} from "../common";
import Session from "../../auth/Session"
import {SessionContext} from "../../auth/SessionProvider"

const vapidPublicKey = 'BFt1UtTVorcl5mfz-ZMEcML3KQ36JmYaDJodUMPgNV1Y44pfrAojC6A5Etp0kn7sXHh_EbNIDfTQbwd6GnGqLuA'

let _pushEnabled: undefined | boolean = undefined

export async function _initialize(session: "NO_SESSION" | Session) {

    if (_pushEnabled !== undefined) {
        // already initialized
        return
    }

    console.log("[pusher] initializeNotifications")

    if (session === "NO_SESSION") {
        console.log("[pusher] NO_SESSION")

        _pushEnabled = false

        // TODO: ensure unsubscribe on client side?

        return
    }

    const config = await w2d2dApiClient.getNotificationsConfig()
    _pushEnabled = config.enabled

    // check client state
    const registration = await navigator.serviceWorker.ready

    const subscription = await registration.pushManager.getSubscription()
    console.log("[pusher] subscription: ", subscription)

    if (config.enabled) {
        if (subscription == null) {
            if (Notification.permission === 'granted') {
                console.log("[pusher] notifications enabled, but no subscription, permission is granted ==> re-subscribe")
                await _subscribe()
            } else {
                console.log("[pusher] notifications enabled, but no subscription, permission is NOT granted ==> ignore")
            }
        } else {
            // Ensure to update the subscription on the server
            await updateWebPushSubscription()
        }
    } else {
        if (subscription) {
            console.log("[pusher] notifications disabled, but existing subscription ==> unsubscribe")
            await _unsubscribe(false)
        }
    }
}

async function _subscribe() {

    const registration = await navigator.serviceWorker.ready
    let subscription = await registration.pushManager.getSubscription()

    let granted = Notification.permission === 'granted'
    if (!granted) {
        granted = await Notification.requestPermission() === 'granted'
    }

    if (granted && subscription == null) {
        const subscription = await registration.pushManager
            .subscribe({
                userVisibleOnly: true,
                applicationServerKey: vapidPublicKey,
            })
        console.log('[pusher] subscribed:', subscription)
        await updateWebPushSubscription()
    }

    await w2d2dApiClient.updateNotificationsConfig( { enabled: true, } )
    _pushEnabled = true
}

export async function _unsubscribe(disable: Boolean) {

    console.log("[pusher] start unsubscribe()")

    const registration = await navigator.serviceWorker.ready
    const subscription = await registration.pushManager.getSubscription()
    if (subscription) {
        await subscription.unsubscribe()
    }

    await updateWebPushSubscription()

    if (disable) {
        await w2d2dApiClient.updateNotificationsConfig({enabled: false,})
        _pushEnabled = false
    }

    console.log("[pusher] finished unsubscribe()")
}

async function updateWebPushSubscription() {
    console.log('[pusher] start updateWebPushSubscription')

    const registration = await navigator.serviceWorker.ready
    let subscription = await registration.pushManager.getSubscription()

    await w2d2dApiClient.updateWebPushSubscription({
        installationId: _initializeInstallationId(),
        subscription: subscription,
    })

    console.log('[pusher] finished updateWebPushSubscription:', subscription)
}

export default function useNotifications() {

    const session = useContext<undefined | "NO_SESSION" | Session>(SessionContext)

    const [pushEnabled, setPushEnabled] = useState(_pushEnabled)

    const subscribe = useCallback(async () => {
        await _subscribe()
        setPushEnabled(_pushEnabled)
    }, [setPushEnabled])

    const unsubscribe = useCallback(async (disable: boolean) => {
        await _unsubscribe(disable)
        setPushEnabled(_pushEnabled)
    }, [setPushEnabled])

    useEffect(() => {

        if (session == undefined) {
            console.log("[pusher] session not yet initialized")
            return
        }

        _initialize(session)
            .then(() => {
                console.log("[pusher] initialized ==> set pushEnabled to ", _pushEnabled)
                setPushEnabled(_pushEnabled)
            })

    }, [session, setPushEnabled])

    return {
        pushEnabled,
        subscribe,
        unsubscribe,
    }
}
