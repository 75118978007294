import {Divider, Fab, List} from "@mui/material";
import {Add} from "@mui/icons-material";
import {ReactElement, useCallback, useEffect, useRef, useState} from "react";
import {Idea, User, w2d2dApiClient} from "../../api-client";
import IdeaPageEntry from "./IdeaPageEntry";
import {useNavigate} from "react-router-dom";
import W2d2dRoutes from "../../W2d2dRoutes"
import useWebPush from "../../appfw/notifications/useWebPush"

const fabStyle = {
    position: 'absolute',
    bottom: 72,
    right: 16,
}

export default function IdeasPage({user}: { user?: User }) {

    const navigate = useNavigate()
    const [ideas, setIdeas] = useState<null | Idea[]>(null)

    const ideasRef = useRef(ideas)

    const updateIdeaById = useCallback(async (ideaId: string) => {

        console.log("start update idea: ", ideaId)

        const ideas = ideasRef.current
        if (ideas == null) {
            // Not initialized, yet
            console.log("No ideas list, skipping update")
            return
        }

        const idea = await w2d2dApiClient.getIdea({id: ideaId})
        if (idea) {

            let modified = false
            let updatedIdeas = ideas.map((i) => {
                if (i.id === idea.id) {
                    modified = true
                    return idea
                }
                return i
            })

            // insert idea if not found, sorted by startsAt
            if (!modified) {
                updatedIdeas.push(idea)
                updatedIdeas.sort((a, b) => {
                        const aTs = a.startsAt ? Date.parse(a.startsAt) : 0
                        const bTs = b.startsAt ? Date.parse(b.startsAt) : 0
                        return aTs - bTs
                    }
                )
            }

            console.log("Updating ideas list")
            setIdeas(updatedIdeas)
        } else {
            // TODO: remove
            console.log("Idea not found: TODO: remove", ideaId)
        }
    }, [ideasRef, setIdeas])

    const updateIdea = useCallback((idea: Idea) => {
        const ideas = ideasRef.current
        if (!ideas) {
            console.log("No ideas list, skipping update")
            return
        }
        console.log("...updating idea: ", idea)
        let modified = false
        let updatedIdeas = ideas.map((i) => {
            if (i.id === idea.id) {
                modified = true
                return idea
            }
            return i
        })
        if (modified) {
            console.log("Updating ideas list")
            setIdeas(updatedIdeas)
        }
    }, [ideas, setIdeas])

    useWebPush("ideas.participation", async (message: any) => {
        console.log("[pusher] Notification received: ", message)

        const ideaId = message.ideaId
        if (ideaId) {
            updateIdeaById(ideaId)
        }
    })

    useWebPush("ideas.notify", async (message: any) => {
        console.log("[pusher] Notification received: ", message)

        const ideaId = message.ideaId
        if (ideaId) {
            updateIdeaById(ideaId)
        }
    })

    const handleClickAdd = useCallback(async () => {
        navigate(W2d2dRoutes.ideas_add)
    }, [])

    useEffect(() => {
        ideasRef.current = ideas
    }, [ideas])

    useEffect(() => {
        let active = true
        w2d2dApiClient.getIdeas()
            .then((ideasList) => {
                if (active) {
                    setIdeas(ideasList.ideas)
                }
            })
        return () => { active = false }
    }, [setIdeas])

    let ideasEl: ReactElement[] = []
    if (user && ideas) {
        // TODO: use memo?
        ideas.forEach((idea) => {
            ideasEl.push(<IdeaPageEntry user={user} idea={idea} key={idea.id} updateIdea={updateIdea}/>)
            ideasEl.push(<Divider key={`${idea.id}-divider`}/>)
        })
    }

    let fabEl
    if (ideas) {
        fabEl = (
            <Fab color="primary" aria-label="Neue Idee" sx={fabStyle} onClick={handleClickAdd}>
                <Add/>
            </Fab>
        )
    }

    return (
        <>
            <List sx={{width: '100%'}}>
                {ideasEl}
            </List>
            {fabEl}
        </>
    )
}
